.block--skewed-right {
    padding-bottom: calc(3rem + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
}

.block--skewed-left {
    padding-bottom: calc(3rem + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%);
}


.container {
    max-width: 1140px;
    margin: 0 auto;
}

.feature__image {
    width: 400px;
    max-width: 100%;
    padding: 5px
}

.feature__image__right {
    float: right;
}

@keyframes pulse {
    0%, 60% {
        transform: scale(1);
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.animated-button {
    animation: pulse 7s infinite;
}

